import React, { ChangeEvent, ForwardedRef, forwardRef, useState, KeyboardEvent } from 'react';
import cn from 'classnames';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { CircleButton } from '@ui';

type InputProps = {
  dataId?: string;
  firstIconDataId?: string;
  secondIconDataId?: string;
  mask?: string;
  label?: string;
  defaultValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  secondIconDisable?: boolean;
  iconDisable?: boolean;
  name?: string;
  secondIconClassName?: string;
  error?: string;
  errorWithoutMessage?: boolean;
  secondIconWithoutBg?: boolean;
  type?: 'email' | 'text' | 'number' | 'color' | 'password';
  icon?: IconNameType;
  secondIcon?: IconNameType;
  iconColor?: 'primary' | 'default';
  min?: number | string;
  max?: number | string;
  maxLength?: number;
  onClick?: () => void;
  secondOnClick?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  formatChars?: { [x: string]: string };
  iconHintTitle?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  stopPropagation?: boolean;
};

const Input = forwardRef((props: InputProps, forwardedRef) => {
  const {
    mask,
    label,
    placeholder,
    name,
    type = 'text',
    defaultValue,
    disabled = false,
    error,
    icon,
    secondIcon,
    iconColor = 'default',
    secondIconClassName,
    onChange,
    secondOnClick,
    errorWithoutMessage,
    onClick,
    onPaste,
    onKeyDown,
    iconHintTitle,
    stopPropagation,
    dataId,
    firstIconDataId,
    secondIconDataId,
    iconDisable,
    secondIconWithoutBg = false,
    secondIconDisable,
    ...remainingProps
  } = props;
  const [, setInputValue] = useState<string | number>('');

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    onChange?.(event);
  }
  function handleOnPaste(event: React.ClipboardEvent<HTMLInputElement>) {
    setInputValue(event.clipboardData.getData('text'));
    onPaste?.(event);
  }

  const inputClassName = ({
    error,
    disabled,
    errorWithoutMessage,
  }: {
    error: boolean;
    disabled: boolean;
    errorWithoutMessage: boolean;
  }) =>
    cn(
      'border-[1px]',
      disabled ? 'text-2color bg-b_light' : 'text-0color',
      error ? 'border-basic_red' : 'border-b_dark',
      errorWithoutMessage ? 'border-basic_red' : 'border-b_dark',
      'text-sm',
      'relative',
      'bg-white',
      'rounded-defaultR',
      'flex',
      'items-center',
      'block',
      'w-full',
      'h-[34px]',
    );

  const iconColorList = {
    default: 'text-3color',
    primary: 'text-action',
    white: 'text_white',
  };

  const inputProps = {
    type,
    name,
    placeholder,
    className:
      'border-[0px] w-full h-[32px] px-[12px] outline-none outline-0 ring-0 rounded-defaultR focus:ring-0',
    onChange: handleOnChange,
    onPaste: handleOnPaste,
    disabled,
    onKeyDown: (event) => onKeyDown?.(event),
  };

  return (
    <div className="w-full relative">
      {label && <label className="block ml-[6px] mb-[2px] text-sm font-[500] ">{label}</label>}
      <div
        className={inputClassName({
          error: !!error,
          disabled,
          errorWithoutMessage: !!errorWithoutMessage,
        })}
      >
        {mask ? (
          <InputMask
            onClick={(e) => e.stopPropagation()}
            mask={mask}
            ref={forwardedRef as ForwardedRef<ReactInputMask>}
            value={defaultValue}
            {...(dataId ? { 'data-id': dataId } : {})}
            {...inputProps}
            {...remainingProps}
          />
        ) : (
          <input
            onClick={(e) => e.stopPropagation()}
            ref={forwardedRef as ForwardedRef<HTMLInputElement>}
            value={defaultValue}
            {...(dataId ? { 'data-id': dataId } : {})}
            {...inputProps}
            {...remainingProps}
          />
        )}

        {icon && (
          <div className="flex justify-center gap-[5px] pr-[3px] bg-transparent pl-[0px]">
            <CircleButton
              hintTitle={iconHintTitle}
              dataId={firstIconDataId}
              onClick={onClick?.bind(null)}
              icon={icon}
              className={cn(iconColorList[iconColor], 'items-center flex')}
              size={14}
              disable={iconDisable}
              stopPropagation={stopPropagation}
            />
            {secondIcon && (
              <div className={`rounded-[10px] ${!secondIconWithoutBg && 'bg-action'}`}>
                <CircleButton
                  disable={secondIconDisable}
                  dataId={secondIconDataId}
                  stopPropagation={stopPropagation}
                  onClick={secondOnClick?.bind(null)}
                  icon={secondIcon}
                  className={`text-white ${secondIconClassName}`}
                  size={14}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {!!error && true && (
        <div className="mt-[1px] whitespace-nowrap absolute text-sm text-red-600 dark:text-red-500">
          {error}
        </div>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
