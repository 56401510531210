import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';
import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { toast } from 'react-toastify';
import serverRoutes from '@app/utils/server-routes';
import { navigationRoutes } from '@app/utils/navigation-routes';
import authStorage from '@app/utils/auth';
import { AuthResponse } from '@app/interfaces/auth';
import { logout } from '@app/store/reducers/authSlice';
import { AUTH_KEY } from '@app/utils/constants';
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const mutex = new Mutex();
// export const baseQuery = fetchBaseQuery({
//   baseUrl,
//   prepareHeaders: (headers, { endpoint }) => {
//     const user = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') as string);
//
//     if (user && endpoint !== 'refresh') {
//       headers.set('Authorization', `Bearer${user.access_token}`);
//     }
//     return headers;
//   },
// });

type StorageDataType = {
  detail: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
};

const headerTokenMutation = (tokenType: 'access' | 'refresh', headers: Headers) => {
  const localState = localStorage.getItem(AUTH_KEY);
  if (localState !== null) {
    const localstorageData = JSON.parse(localState) as StorageDataType;
    const token =
      tokenType === 'access' ? localstorageData['access_token'] : localstorageData['refresh_token'];
    if (token) {
      const tokenValue = `Bearer ${token}`;
      headers.set('Authorization', tokenValue);
    }
  }
  return headers;
};
const baseQueryWithRefreshToken = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => headerTokenMutation('refresh', headers),
});
const baseQueryAccessToken = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => headerTokenMutation('access', headers),
});

const loginEndpointPass = serverRoutes.login;

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQueryAccessToken(args, api, extraOptions);
  if (result?.meta?.response?.status === 500) {
    toast.error('Ошибка на сервере');
    return { ...result, data: undefined };
  }
  if (result?.meta?.response?.status === 403) {
    result.meta.request.redirect !== 'manual' && toast.error('Нет доступа');
    return { ...result, meta: { ...result.meta, request: {}, response: {} }, data: undefined };
  }
  if (result?.meta?.response?.status === 401 && result?.meta?.request.url !== loginEndpointPass) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQueryWithRefreshToken(
          { url: serverRoutes.refresh_token },
          api,
          extraOptions,
        );
        if (refreshResult?.meta?.response?.status === 401) {
          await authStorage.logOut();
          api.dispatch(logout());
          window.location.pathname = navigationRoutes.login;
        } else {
          const data = refreshResult.data as AuthResponse;
          if (data.refresh_token && data.access_token) {
            await authStorage.signIn(data);
          }
          result = await baseQueryAccessToken(args, api, extraOptions);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryAccessToken(args, api, extraOptions);
    }
  }
  return result;
};
