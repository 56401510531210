import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Button, EasyFilterView } from '@ui';

import useTranslation from '@app/hooks/use-translation';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { AllStatusProject, GroupSharedStatus } from '@app/interfaces/analytics';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useCreateLiveReportMutation } from '@app/store/api/live-reports.api';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { useAppSelector } from '@app/store/store';
import { TableMetricRecords } from '@app/components';

export interface GetFilterParams {
  offset: number | string;
  limit: number;
  filter: Filter & Range;
  sortBy?: string;
  sortDesc?: boolean;
  preset_mode?: boolean;
}
const DEFAULT_RECORDS_LIMIT = 10;
const DEFAULT_RECORDS_OFFSET = 0;
const LastProjectRecordsPage = () => {
  const { t } = useTranslation('pages.recordsAp');
  const navigate = useNavigate();
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const DEFAULT_FILTER_SETUP: GetFilterParams = {
    offset: DEFAULT_RECORDS_OFFSET,
    limit: userSettings?.tablesLimit?.analyticProject?.records || DEFAULT_RECORDS_LIMIT,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  };
  const [filterParams, changeFilterParams] = useState<GetFilterParams>(DEFAULT_FILTER_SETUP);
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: project?.project_id || '',
    },
    { skip: !project?.project_id },
  );
  // const { data: MetricRecords } = useGetMetricRecordsQuery({ project_id: project.project_id });
  // console.log('MetricRecords', MetricRecords);
  const [createLiveReportMutation] = useCreateLiveReportMutation();
  useEffect(() => {
    changeFilterParams((prev) => ({
      ...prev,
      limit: userSettings?.tablesLimit?.analyticProject?.records || 10,
    }));
  }, [userSettings?.tablesLimit?.analyticProject?.records]);
  // handlers
  function getFilteringKeys() {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }
  function cancelFilter() {
    changeFilterParams((prev) => ({
      ...prev,
      filter: DEFAULT_FILTER_SETUP.filter,
    }));
  }
  const getSaveBtnDisabled = () => {
    if (
      filterParams.filter.filter.length === 0 &&
      filterParams.filter.range.parameters.length === 0
    )
      return true;
    if (project?.status !== AllStatusProject.CREATED && project?.status !== AllStatusProject.PAUSE)
      return true;
    return !project?.owner && project?.shared === GroupSharedStatus.VIEW;
  };
  function handleFilterChange(newFilter: Filter & Range) {
    changeFilterParams((prev) => ({ ...prev, filter: newFilter }));
  }
  function createLiveReport() {
    if (!project) return;
    const result = {
      name: `${project.name}`,
      description: project.description,
      filter: filterParams.filter,
      project_id: project.project_id,
    };
    createLiveReportMutation(result)
      .unwrap()
      .then((data) => {
        navigate(
          `/${navigationRoutes.reports}/${data.live_report_id}/${navigationRoutes.settings}`,
          {
            state: navigationRoutes.reports,
          },
        );
      });
  }
  // jsx markup
  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;
  const getFooterFilter = (
    <div className="flex">
      <Button
        label={
          process.env.REACT_APP_THEME === 'rtk'
            ? t('filter.buttons.create_report_rtk')
            : t('filter.buttons.create_report')
        }
        disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
        onClick={() => createLiveReport()}
      />
      <Button
        fill="linked"
        label={t('filter.buttons.cancel')}
        onClick={() => cancelFilter()}
        disabled={getSaveBtnDisabled()}
      />
    </div>
  );
  return (
    <div>
      <div>
        <EasyFilterView
          dataIdStartDate="analytic-project-records-date-from"
          dataIdEndDate="analytic-project-records-date-to"
          data={filterParams.filter}
          isLoading={projectMetricLoading}
          titleMarkup={getTitleFilter}
          footerMarkup={getFooterFilter}
          onChangeHandler={handleFilterChange}
          keysForFiltering={getFilteringKeys() || []}
          disabledChangeButton={!project?.project_id}
        />
        <div className="relative">
          <TableMetricRecords
            project_id={project?.project_id || ''}
            filterParams={filterParams}
            changeFilterParams={changeFilterParams}
          />
        </div>
        {/*<ProjectRecordsTableBlock*/}
        {/*  project_id={project?.project_id || ''}*/}
        {/*  filterParams={filterParams}*/}
        {/*  changeFilterParams={changeFilterParams}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default LastProjectRecordsPage;
