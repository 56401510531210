import React, { FC } from 'react';
type HandleMetricSpecialTogglePropsType = {
  valuesList: Array<{ value: string; title: string }>;
  isActive: string;
  onChange(value: string): void;
  disabled?: boolean;
};
const HandleMetricSpecialToggle: FC<HandleMetricSpecialTogglePropsType> = (props) => {
  const { valuesList, isActive, onChange, disabled = false } = props;
  // <div>Число</div>
  // <div>Текст</div>
  return (
    <div
      className={`w-full flex items-center justify-around bg-basic_app_bg rounded-[10px] p-[3px] shadow-md`}
    >
      {valuesList.map((currentValue) => (
        <div
          onClick={() => !disabled && onChange(currentValue.value)}
          className={`${
            isActive === currentValue.value && 'bg-white'
          } py-[8px] transition-all duration-300 ${
            !disabled ? 'hover:bg-white' : '!cursor-not-allowed'
          } w-full text-[14px] text-center cursor-pointer  rounded-[10px]`}
          key={currentValue.value}
        >
          {currentValue.title}
        </div>
      ))}
    </div>
  );
};

export default HandleMetricSpecialToggle;
