import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import { WaveSurfer, WaveForm, Region } from 'wavesurfer-react';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min';
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers';
import { BiZoomIn, BiZoomOut, BiPause } from 'react-icons/bi';
import { BsPlayFill } from 'react-icons/bs';
import AudioVolumeRange from '@app/components/audio-build/waveaudio/audio-volume-range';
import useTranslation from '@app/hooks/use-translation';
import connector from '@app/utils/store';
import { CircleButton, Toggle, TooltipWrapper } from '@ui';
import useDownloadLink from '@app/hooks/use-download-link';
import twTheme from '@app/themes';

import { emotionToColorConverter } from '@app/constants/emotions';

import { useAudioContext } from '@app/components/audio-build/record-provider';

import { WAVE_COMPONENT_CONFIG } from '../data/audio-data';
import { AudioRegionItem } from '../audio-build.types';

import PlaybackSpeedSelect from './playback-speed-select';
import { circleBlueButtonClassName } from './wave-audio.styles';
import { RegionType, WaveAudioProps } from './wave-audio.types';

const plugins = [
  {
    plugin: MarkersPlugin,
    options: {
      markers: [{ draggable: false }],
    },
  },
  {
    plugin: RegionsPlugin,
    options: { dragSelection: false, overlay: 20 },
  },
  {
    plugin: TimelinePlugin,
    options: {
      container: '#timeline',
      primaryColor: '#FFFFFF',
      secondaryColor: 'rgb(26 33 40 / 0%)',
      primaryFontColor: '#A6ABBA',
      secondaryLabelInterval: 0,
      notchPercentHeight: 0,
      partialRender: true,
      height: 10,
      barWidth: 2,
      primaryLabelInterval: 10,
      secondaryFontColor: 'rgb(26 33 40 / 0%)',
    },
  },
];

const WaveAudio: FC<WaveAudioProps> = (props) => {
  const {
    activeMetricWords,
    onChangeActiveMessageId,
    activeMessageId,
    activeChartTiming,
    dialogs,
    audio,
    wordsTimeList,
    channelSettings,
    changeFullScreen,
    recordName,
    emotionTrack,
  } = props;
  const splitChannelsOptions = {
    overlay: false,
    channelColors: Array(WAVE_COMPONENT_CONFIG.channelsLimit)
      .fill('_')
      .map(() => {
        return {
          backgroundColor: '#000000',
          progressColor: '#7A4487',
          waveColor: '#a15db1',
        };
      }),
  };
  const [durationTime, setDurationTime] = useState<string>(WAVE_COMPONENT_CONFIG.initialTimeFormat);
  const [fullTime, setFullTime] = useState<string>(WAVE_COMPONENT_CONFIG.initialTimeFormat);
  const [isPause, setIsPause] = useState<boolean>(true);
  const [regions, setRegions] = useState<Array<AudioRegionItem>>([]);
  const [metricRegions, setMetricRegions] = useState<Array<AudioRegionItem>>([]);
  const [currentZoom, setCurrentZoom] = useState<number>(0);
  const { toggleEmotions, changeToggleEmotions } = useAudioContext();
  const wavesurferRef = useRef<WaveSurfer>();
  const downloadAudio = useDownloadLink();
  const { t } = useTranslation('components.audio');
  const regionCreateHandler = useCallback(
    ({ regions, type = 'default' }: { regions: Array<AudioRegionItem>; type?: RegionType }) => {
      const newRegionsForRender = regions.filter(
        (region) => region?.data?.systemRegionId === undefined,
      );
      const idRegionData = { data: { systemRegionId: -1 } };
      if (newRegionsForRender.length < 1) return;
      if (type === 'emotions') {
        setMetricRegions(
          newRegionsForRender
            .filter((metric) => {
              return !metric.color.startsWith('#undefined');
            })
            .map((region) => ({
              ...region,
              ...idRegionData,
            })),
        );
      } else {
        setRegions([
          {
            ...newRegionsForRender[0],
            ...idRegionData,
          },
        ]);
      }
    },
    [],
  );

  useEffect(() => {
    wavesurferRef.current?.clearMarkers();
    if (activeMetricWords?.settings?.ids) {
      changeToggleEmotions(false);
      setRegions([]);
      let index = 1;
      for (const wordIds of activeMetricWords.settings.ids) {
        wavesurferRef.current?.addMarker({
          color: `#${activeMetricWords.color}`,
          position: index % 2 ? 'top' : 'bottom',
          time: wordsTimeList[wordIds]?.from / WAVE_COMPONENT_CONFIG.currentTimingMs,
        });
        index++;
      }
    } else {
      //TODO: Изменить на значение TRUE по умолчанию когда эмоции войдут в резиз
      changeToggleEmotions(false);
    }
  }, [activeMetricWords, changeToggleEmotions, regionCreateHandler, wordsTimeList]);

  const emotionRegions = useCallback(() => {
    emotionTrack.length &&
      toggleEmotions &&
      regionCreateHandler({
        regions: emotionTrack.map((emotion, index) => ({
          id: String(index),
          start: emotion.from / WAVE_COMPONENT_CONFIG.currentTimingMs,
          end: emotion.to / WAVE_COMPONENT_CONFIG.currentTimingMs,
          color: emotionToColorConverter({ tag: emotion.tag, score: emotion.score }),
        })),
        type: 'emotions',
      });
  }, [emotionTrack, regionCreateHandler, toggleEmotions]);

  useEffect(() => {
    toggleEmotions ? emotionRegions() : regionResetHandler();
  }, [emotionRegions, toggleEmotions]);

  useEffect(() => {
    if (activeChartTiming && wavesurferRef) {
      activeChartTiming?.from &&
        wavesurferRef.current?.play(activeChartTiming.from / WAVE_COMPONENT_CONFIG.currentTimingMs);
    }
  }, [activeChartTiming]);

  const regionResetHandler = () => {
    setRegions([]);
  };

  const handleFindChartRegionId = useCallback(() => {
    const foundedRegions = dialogs.filter((textItem) => {
      const timeMarker =
        (wavesurferRef?.current?.getCurrentTime() || 0) * WAVE_COMPONENT_CONFIG.currentTimingMs;
      return wavesurferRef?.current?.getCurrentTime()
        ? Math.round(textItem.from) <= timeMarker &&
            Math.round(textItem.to || Number(durationTime) / 1000) >= timeMarker
        : false;
    });
    if (foundedRegions.length) {
      if (
        foundedRegions.filter((regionInSearch) => activeMessageId?.includes(regionInSearch.id))
          .length !== activeMessageId?.length
      ) {
        onChangeActiveMessageId?.((prevState) =>
          foundedRegions.filter((regionInSearch) => prevState?.includes(regionInSearch.id))
            .length !== prevState?.length
            ? foundedRegions.map((region) => region.id)
            : prevState,
        );
        const currentRegions: Array<{ id: string; start: number; end: number; color: string }> = [];
        for (const region of foundedRegions) {
          currentRegions.push({
            id: `${WAVE_COMPONENT_CONFIG.regionPrefix}-${region.id}`,
            start: region.from / WAVE_COMPONENT_CONFIG.currentTimingMs,
            end: region.to / WAVE_COMPONENT_CONFIG.currentTimingMs,
            color: WAVE_COMPONENT_CONFIG.defaultRegionColor,
          });
        }
        regionCreateHandler({ regions: currentRegions });
      }
    }
  }, [activeMessageId, dialogs, durationTime, onChangeActiveMessageId, regionCreateHandler]);

  const handleWSMount = useCallback(
    (waveSurfer) => {
      wavesurferRef.current = waveSurfer;
      if (wavesurferRef.current && audio) {
        wavesurferRef.current.on('interaction', () => {
          regionResetHandler();
        });
        wavesurferRef.current.on('seek', () => {
          handleFindChartRegionId();
        });
        wavesurferRef.current?.loadBlob(audio);
        wavesurferRef.current.on('ready', () => {
          setFullTime(formatTime(wavesurferRef?.current?.getDuration() || 0));
        });
        wavesurferRef.current?.clearMarkers();
        wavesurferRef.current?.on('region-created', (region) => {
          region.drag = false;
          region.resize = false;
        });
        wavesurferRef.current?.on('play', () => {
          setIsPause(false);
        });
        wavesurferRef.current?.on('pause', () => {
          setIsPause(true);
          regionResetHandler();
        });
        wavesurferRef.current?.on('audioprocess', () => {
          if (wavesurferRef.current?.isPlaying()) {
            if (wavesurferRef?.current?.getCurrentTime() !== undefined) {
              handleFindChartRegionId();
            }
            setDurationTime(formatTime(Math.round(wavesurferRef.current.getCurrentTime() || 0)));
          }
        });
      }
    },
    [audio, handleFindChartRegionId],
  );

  const play = useCallback(() => {
    wavesurferRef?.current?.playPause();
  }, []);

  function handleChangeVolume(newVolume: number) {
    wavesurferRef.current?.setVolume(newVolume);
  }
  function handleChangeAudioSpeed(playback: number) {
    wavesurferRef.current?.setPlaybackRate(playback);
  }

  function handleChangeAudioZoom(zoom: number) {
    if (zoom >= WAVE_COMPONENT_CONFIG.zoomLimit[0] && zoom <= WAVE_COMPONENT_CONFIG.zoomLimit[1]) {
      wavesurferRef.current?.zoom(zoom);
      setCurrentZoom(zoom);
    }
  }

  function formatTime(time: number) {
    return [
      Math.floor((time % 3600) / 60), // minutes
      ('00' + Math.floor(time % 60)).slice(-2), // seconds
    ].join(':');
  }

  function handleChangeFullScreenModeAndUpdate() {
    changeFullScreen();
    setCurrentZoom(10);
    wavesurferRef.current?.zoom(10);
    setTimeout(() => {
      setCurrentZoom(0);
      wavesurferRef.current?.zoom(0);
    }, 10);
  }
  const renderAllRegions = () => {
    const concatRegions = toggleEmotions
      ? regions.length
        ? [...metricRegions, regions[0]]
        : metricRegions
      : regions[0]
      ? [regions[0]]
      : [];
    if (!concatRegions.length) return <></>;

    return (
      <>
        {concatRegions?.map((regionProps, index) => {
          return <Region key={`${regionProps.id}_${index}`} {...regionProps} />;
        })}
      </>
    );
  };
  const renderChannelTitles = Array(WAVE_COMPONENT_CONFIG.channelsLimit)
    .fill('+')
    .map((_, channelIndex) => {
      return (
        <div
          key={channelSettings[channelIndex].settings_channels_id}
          className="basis-1/2 justify-center flex items-center"
        >
          <TooltipWrapper
            content={channelSettings[channelIndex].name}
            id={channelSettings[channelIndex].name}
          >
            <div className="block -rotate-90 text-1color text-[10px] font-bold  whitespace-nowrap">
              {channelSettings[channelIndex].name.length > 10
                ? `${channelSettings[channelIndex].name.slice(0, 8)}...`
                : channelSettings[channelIndex].name}
            </div>
          </TooltipWrapper>
        </div>
      );
    });

  return (
    <div className="bg-white relative py-4 rounded-lg shadow-lg">
      <div className="inline-flex items-center w-full justify-between mb-[17px] px-[25px]">
        <div className="inline-flex items-center justify-between">
          <h3 className="text-lg font-bold">{t('audoigramm')}</h3>
        </div>
        <div className="inline-flex items-center mt-[5px]">
          <div className="bg-[#F5F5F7] text-3color inline-flex items-center rounded-[10px] text-[15px] px-[10px] py-[3px]">
            <div onClick={() => handleChangeAudioZoom(currentZoom - 10)}>
              <BiZoomOut size={18} className="text-3color cursor-pointer hover:opacity-80" />
            </div>
            <div className="ml-[5px] mr-[9px] w-[33px] text-center select-none">{currentZoom}%</div>
            <div onClick={() => handleChangeAudioZoom(currentZoom + 10)}>
              <BiZoomIn size={18} className="text-3color cursor-pointer hover:opacity-80" />
            </div>
          </div>
          <div className="inline-flex items-center">
            <div className="ml-[18px] z-[1]">
              <CircleButton
                icon="ResizeIcon"
                hintTitle={t('popup_hints.oscillogram_view')}
                size={14}
                onClick={handleChangeFullScreenModeAndUpdate}
                hintPlace="top"
                className="text-3color cursor-pointer hover:opacity-80"
              />
            </div>
            <div className="ml-[15px] z-[1]">
              <CircleButton
                icon="DownloadCloudIcon"
                onClick={downloadAudio.bind(null, {
                  name: recordName || WAVE_COMPONENT_CONFIG.defaultRecordName,
                  format: 'wav',
                  file: audio,
                })}
                hintPlace="top"
                hintTitle={t('popup_hints.download_record')}
                size={19}
                className="text-3color cursor-pointer hover:opacity-80"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-[5px] min-h-[150px] flex">
        <div className="relative flex-1 bg-gradient-to-t from-white via-bg_components via-50% to-white rounded-l-[13px]">
          <div className="w-[31px] absolute h-full flex flex-col pb-[10px]">
            {renderChannelTitles}
          </div>
          <div className="pl-[40px]">
            <WaveSurfer onMount={handleWSMount} plugins={plugins}>
              <WaveForm
                barRadius={2}
                id="waveform"
                cursorWidth={1.5}
                splitChannels={true}
                splitChannelsOptions={splitChannelsOptions}
                cursorColor={twTheme.action}
                barWidth={2}
                barHeight={1}
                height={70}
              >
                {renderAllRegions()}
              </WaveForm>
              <div id="timeline" className="z-1 bg-white rounded-t-[20px]" />
            </WaveSurfer>
          </div>
        </div>
      </div>
      <div className="inline-flex w-full items-center justify-between mt-[22px] pl-[27px] pr-[22px]">
        <div className="inline-flex items-center">
          <TooltipWrapper content={t('popup_hints.record_play')} id={'record_play_button'}>
            <button className={circleBlueButtonClassName} onClick={play}>
              {isPause ? <BsPlayFill size={19} /> : <BiPause size={19} />}
            </button>
          </TooltipWrapper>
          <div className="bg-gray-100 text-3color justify-center inline-flex text-center w-[102px] items-center rounded-[10px] text-[15px] px-[10px] py-[3px]">
            <div className="w-[35px] text-0color">{durationTime}</div>/
            <div className="w-[35px] text-3color">{fullTime}</div>
          </div>
          {process.env.REACT_APP_THEME !== 'rtk' && (
            <div className="flex items-center ml-[20px]">
              <Toggle
                checked={toggleEmotions}
                disabled={true}
                onChecked={changeToggleEmotions.bind(null, (prevState) => !prevState)}
              />
              <span className=" flex gap-[3px] items-center text-[12px] ml-[6px] mb-[2px] text-4color">
                {t('emotions')} <span className=" flex self-start text-[8px]">({t('soon')})</span>
              </span>
            </div>
          )}
        </div>
        <div className="inline-flex items-center">
          <div className="mr-[14px]">
            <PlaybackSpeedSelect
              hint={t('popup_hints.playing_speed')}
              onChange={handleChangeAudioSpeed}
            />
          </div>
          <AudioVolumeRange onChange={handleChangeVolume} />
        </div>
      </div>
    </div>
  );
};

export default connector(WaveAudio);
