import React, { FC, ReactNode, useRef, MouseEvent, useState } from 'react';
import cn from 'classnames';

import useOnClickOutside from '@app/hooks/use-onclick-outside';
import { Button, CircleButton } from '@ui';
import { easyModalClassName } from '@app/components/ui/easy-modal/easy-modal.styles';
import useTranslation from '@app/hooks/use-translation';

import { TrashIcon } from '../icons/icons-list';

export type EasyModalVariant =
  | 'small'
  | 'medium'
  | 'large'
  | 'mediumW650'
  | 'largeW1127'
  | 'smallW230'
  | 'smallW300'
  | 'removal'
  | 'medium484'
  | 'largeW1200'
  | 'maximum';

type EasyModalProps = {
  show: boolean;
  onClose?: () => void;
  label?: string | ReactNode;
  variant?: EasyModalVariant;
  children: ReactNode;
  onSuccess?: (event: MouseEvent<HTMLButtonElement>, handlerClose: () => void) => void;
  onRemove?: () => void;
  successText?: string;
  dataId?: string;
  failText?: string;
  removeText?: string;
  withoutFooter?: boolean;
  withoutLabel?: boolean;
  withoutContentPadding?: boolean;
  isAutoClosable?: boolean;
  noOverflowContent?: boolean;
  isNotContentClosable?: boolean;
  customLayer?: number;
  disabled?: boolean;
  isFull?: boolean;
};

const EasyModal: FC<EasyModalProps> = (props) => {
  const {
    show,
    onClose,
    children,
    label,
    variant = 'medium',
    onRemove,
    onSuccess,
    failText,
    successText,
    removeText,
    withoutFooter = false,
    withoutContentPadding = false,
    withoutLabel = false,
    isAutoClosable = true,
    noOverflowContent,
    isNotContentClosable,
    customLayer,
    disabled,
    isFull,
    dataId,
  } = props;
  const { t } = useTranslation('components.easyModal');
  const [fulledScreen, setFulledScreen] = useState(isFull || false);
  const modalRef = useRef(null);
  const variantSizes: Record<EasyModalVariant, string> = {
    small: 'max-w-[600px]',
    smallW230: 'max-w-[230px]',
    smallW300: 'max-w-[300px]',
    medium: 'max-w-[879px]',
    mediumW650: 'max-w-[650px]',
    large: 'max-w-[1032px]',
    largeW1127: 'max-w-[1127px]',
    largeW1200: 'max-w-[1200px]',
    removal: 'max-w-[484px]',
    medium484: 'max-w-[484px]',
    maximum: 'max-w-full',
  };

  const modalFooterClassName = cn(
    'h-[92px]',
    'px-[40px]',
    'py-[40px]',
    'border-t',
    'border-b_dark',
    'w-full',
    'inline-flex',
    'items-center',
    'justify-between',
  );

  useOnClickOutside(modalRef, () => !isNotContentClosable && onClose?.());

  function handlerClose() {
    onClose?.();
  }
  function handlerRemove() {
    onRemove?.();
    handlerClose();
  }

  function handlerSuccess(event: MouseEvent<HTMLButtonElement>) {
    onSuccess?.(event, handlerClose);
    isAutoClosable && handlerClose();
  }

  const renderModalFooter =
    variant === 'removal' ? (
      <></>
    ) : !withoutFooter ? (
      <div className={modalFooterClassName}>
        <div className="inline-flex items-center gap-[18px]">
          {onSuccess && (
            <Button
              disabled={disabled}
              size="medium"
              label={successText || t('success')}
              onClick={(event) => handlerSuccess(event)}
            />
          )}
          {onClose && (
            <Button
              size="medium"
              fill="linked"
              label={failText || t('cancel')}
              onClick={handlerClose}
            />
          )}
        </div>
        <div>
          {onRemove && (
            <Button
              size="medium"
              variant="danger"
              onClick={handlerRemove}
              fill="linked"
              icon="TrashIcon"
              label={removeText || t('remove')}
            />
          )}
        </div>
      </div>
    ) : (
      <></>
    );

  return (
    <div
      className={easyModalClassName({ show })}
      style={customLayer ? { zIndex: customLayer } : { zIndex: 9998 }}
    >
      <div
        ref={modalRef}
        className={cn(
          'w-full',
          'ease-in-out',
          'duration-150',
          'relative',
          'inline-flex',
          'flex-col',
          fulledScreen ? 'h-[calc(100%-15px)]' : 'max-h-[calc(100%-100px)]',
          show ? 'scale-10 visible' : 'scale-0 invisible',
          'z-50',
          'bg-white',
          'rounded-defaultR',
          'min-h-[150px]',
          fulledScreen ? 'max-w-full' : variantSizes[variant],
        )}
      >
        {!withoutLabel && (
          <div className="inline-flex w-full mt-[16px] justify-between">
            <div className="w-full">
              {variant === 'removal' ? (
                <div className="flex justify-center flex-col items-center pt-[35px]">
                  <div className="w-[57px] h-[57px] rounded-full flex justify-center items-center bg-basic_red/[.1]">
                    <TrashIcon size={25} className="text-basic_red" />
                  </div>
                  {label && (
                    <div className="text-0color px-[42px] w-[500px] text-center mt-[24px] word-wrap overflow-wrap break-word text-[24px] font-[700] ">
                      {label}
                    </div>
                  )}
                </div>
              ) : (
                label && (
                  <div className="text-0color px-[42px] text-center mt-[24px] text-[24px] font-[700]">
                    {label}
                  </div>
                )
              )}
            </div>
            {typeof isFull !== 'undefined' && (
              <div className="relative top-[-1px] right-[25px]">
                <CircleButton
                  icon="ResizeIcon"
                  size={15}
                  className="text-bg5_modal"
                  onClick={() => setFulledScreen((prevState) => !prevState)}
                />
              </div>
            )}
            <CircleButton
              className="relative top-[0px] right-[35px]"
              size={14}
              onClick={onClose?.bind(null)}
              icon="XIcon"
            />
          </div>
        )}
        <div
          className={cn(
            fulledScreen ? 'max-h-[calc(100%-100px)]' : 'max-h-[calc(100%-200px)]',
            'h-fit w-full inline-flex',
            !noOverflowContent && 'overflow-hidden overflow-y-auto z-50',
          )}
        >
          <div className={`w-full h-full ${withoutContentPadding ? '' : 'px-[40px] mt-[12px]'} `}>
            {children}
            {variant === 'removal' && (
              <div className="flex flex-col items-center py-[40px]">
                <Button
                  dataId={dataId}
                  label={t('remove')}
                  variant="danger"
                  className="w-[228px] h-[44px] mb-[13px]"
                  onClick={handlerRemove}
                />
                <Button
                  label={t('cancel')}
                  fill="linked"
                  className="mb-[26px]"
                  onClick={handlerClose}
                />
              </div>
            )}
          </div>
        </div>
        {renderModalFooter}
      </div>
    </div>
  );
};

export default EasyModal;
