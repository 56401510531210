import React from 'react';
import { Outlet } from 'react-router';
import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/store/store';
import { useGetAnalyticsProjectsListQuery } from '@app/store/api/analytics-projects.api';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { NotFoundedPage } from '@app/pages';
import useTranslation from '@app/hooks/use-translation';
const LastProjectOutlet = () => {
  const { t } = useTranslation('pages.recordsByAnalytics');
  const { recordProjectId } = useParams();
  const { data: analyticsProjectsGroups } = useGetAnalyticsProjectsListQuery(null, {
    skip: typeof recordProjectId === 'string',
  });
  const { userSettings } = useAppSelector((state) => state.userSettings);

  if (!recordProjectId) {
    const lastProjectId =
      userSettings?.lastProjectRecords ||
      analyticsProjectsGroups?.find((analyticProject) => analyticProject.is_default)?.projects?.[0]
        ?.project_id ||
      '';
    if (!lastProjectId) return <NotFoundedPage text={t('error_page_label')} />;
    return <Navigate to={`/${navigationRoutes.projectRecords}/${lastProjectId}`} />;
  }
  return <Outlet />;
};

export default LastProjectOutlet;
