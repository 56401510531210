module.exports = {
  project_records: 'Записи проекта',
  records: 'Записи',
  recordsId: 'Аудиозапись',
  analytic_project: 'Проект',
  analytics: 'Аналитические проекты',
  template: 'Шаблоны',
  reports: 'Отчеты',
  report: 'Отчет',
  dictionaries: 'Словари',
  dictionariesCreate: 'Добавление словаря',
  report_settings: 'Настройки отчета',
  report_list: 'Записи отчета',
  settings: 'Настройки',
  preset_templates: 'Шаблоны пресетов',
  preset_template: 'Пресет',
  metric_templates: 'Шаблоны метрик',
  wordsCloud: 'Облако слов',
  wordsNGram: 'Облако фраз',
};
