import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  MetricListArrType,
  MetricObjType,
  MetricTypesArrType,
  TagMetricRequest,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import { metricRecordsApi } from '@app/store/api/metric-records.api';

import { baseQuery } from '../baseQuery';

export const metricsApi = createApi({
  reducerPath: 'metricsApi',
  tagTypes: ['metrics', 'singleMetric'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    // metric types
    getMetricTypesList: build.query<MetricTypesArrType[], void>({
      query: () => ({
        url: serverRoutes.metricsTypes,
      }),
    }),
    // project metric
    getProjectMetric: build.query<MetricObjType, { project_id: string; metric_id: string }>({
      query: (params) => ({
        url: serverRoutes.metricProject,
        params,
      }),
      providesTags: ['singleMetric'],
    }),
    getProjectMetricsList: build.query<MetricListArrType[], { project_id: string }>({
      query: (params) => ({
        url: serverRoutes.metric,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ metric_id }) => ({
                type: 'metrics' as const,
                metric_id,
              })),
              'metrics',
            ]
          : ['metrics'],
    }),
    createProjectMetricGroup: build.mutation<
      MetricObjType,
      {
        params: {
          project_id: string;
        };
        body: MetricObjType;
      }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.metricProject,
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: ['metrics'],
    }),
    editProjectMetricGroup: build.mutation<
      MetricObjType,
      {
        params: {
          project_id: string;
          metric_id: string;
        };
        body: MetricObjType;
      }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.metricProject,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['metrics', 'singleMetric'],
    }),
    deleteProjectMetric: build.mutation({
      query: (params: { project_id: string; metric_id: string }) => ({
        url: serverRoutes.projectMetric,
        method: 'DELETE',
        params,
        redirect: 'manual',
      }),

      invalidatesTags: ['metrics'],
    }),
    /* Проставление значения ручной метрики */
    setValueTagMetric: build.mutation<{ success: boolean }, TagMetricRequest>({
      query: ({ params, body }) => ({
        url: serverRoutes.projectMetricValue,
        method: 'PUT',
        params,
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(metricRecordsApi.util.invalidateTags(['metricRecords']));
      },
      // invalidatesTags: ['metrics'],
    }),
  }),
});

export const {
  useGetMetricTypesListQuery,
  useGetProjectMetricsListQuery,
  useDeleteProjectMetricMutation,
  useLazyGetProjectMetricQuery,
  useGetProjectMetricQuery,
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
  useSetValueTagMetricMutation,
} = metricsApi;
