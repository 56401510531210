import { CircleButton, EditText, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';
import {
  useDeleteMetricTemplateGroupMutation,
  useEditMetricTemplateGroupMutation,
} from '@app/store/api/metric-template.api';
import { EasyModal } from '@ui';

type PropsType = {
  name?: string;
  isDefault?: boolean;
  metric_templates_group_id?: string;
  active?: boolean;
  templatesCount?: number;
  dataId?: string;
  setActiveAccordionItem: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
};
export const AccordionTitle: React.FC<PropsType> = ({
  name,
  active,
  templatesCount,
  metric_templates_group_id,
  isDefault,
  // setActiveAccordionItem,
  dataId,
}) => {
  const { t } = useTranslation('pages.metricTemplates');
  const [edit, setEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [id, setId] = React.useState('');
  //api
  const [editMetricTemplateGroup] = useEditMetricTemplateGroupMutation();
  const [deleteMetricTemplateGroup] = useDeleteMetricTemplateGroupMutation();
  const deleteName = () => {
    if (metric_templates_group_id) {
      setId(metric_templates_group_id);
      setModalDelete(true);
    }
  };
  const deleteMetricTemplatesGroup = () => {
    deleteMetricTemplateGroup({ id });
    //   .then(() => {
    //   const accordionListFromStorage = localStorage.getItem('metric_accordion')?.split(',');
    //   const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
    //     (item) => item !== id,
    //   ) as string[];
    //   localStorage.setItem('metric_accordion', filteredAccordionListFromStorage.join(','));
    //   setActiveAccordionItem(filteredAccordionListFromStorage);
    // });

    setModalDelete(false);
  };
  const editGroupName = (groupName: string) => {
    const value = groupName.replace(NameAndDescriptionPattern, '');
    metric_templates_group_id &&
      editMetricTemplateGroup({
        params: { id: metric_templates_group_id },
        body: { name: value },
      }).then(() => {
        setEdit(false);
      });
  };
  useEffect(() => {
    if (!active) {
      setEdit(false);
    }
  }, [active]);

  return (
    <>
      <EasyModal
        dataId={`metric-templates-${name}-delete-confirm`}
        show={modalDelete}
        onClose={setModalDelete.bind(null, false)}
        onRemove={() => deleteMetricTemplatesGroup()}
        label={`${t('warning')} "${name}"`}
        variant="removal"
        withoutFooter
      >
        <div className="text-center">{t('group_delete')}</div>
      </EasyModal>

      <div
        className="flex items-center gap-[15px]"
        {...(dataId ? { 'data-id': String(dataId) } : {})}
      >
        <EditText
          visibleInput={isDefault ? false : active}
          editIconSize={14}
          textStyle="font-[700] text-[16px] text-2color leading-[110%] tracking-tight"
          text={name as string}
          onEdited={editGroupName}
        />
        {!isDefault && active && !edit && (
          <TooltipWrapper
            content={t('popup_hints.delete_button')}
            id={t('popup_hints.delete_button') + v4()}
          >
            <CircleButton
              dataStatus={`${templatesCount === 0 ? 'enable' : 'disable'}`}
              dataId={`${name}-delete`}
              size={14}
              onClick={templatesCount === 0 ? () => deleteName() : undefined}
              className={`${templatesCount === 0 ? 'text-dangerBg' : 'text-3color'}`}
              icon={'TrashIcon'}
              stopPropagation
            />
          </TooltipWrapper>
        )}
      </div>

      {!active && (
        <div className="flex justify-center items-center text-xs w-[17px] h-[17px] rounded-full ml-[13px] text-[11px] bg-defaultBgBtn text-white">
          <TooltipWrapper
            content={t('popup_hints.templates_list_count')}
            id={t('popup_hints.templates_list_count') + v4()}
            className="text-white"
          >
            {templatesCount}
          </TooltipWrapper>
        </div>
      )}
      {isDefault && (
        <div className="flex items-center justify ml-[10px]">
          <Icon name={'LockCircle'} className="text-2color ml-[px]" size={15} />
        </div>
      )}
    </>
  );
};
