module.exports = {
  popup_hints: {
    to_charts: 'Перейти к диаграммам отчета',
    to_records: 'Перейти к записям отчета',
    to_record_watch: 'Перейти к просмотру записи',
    download_record: 'Скачать аудиозапись',
    download_report: 'Скачать отчет',
    settings_icon: 'Настройка отчета',
    delete_icon: 'Удалить',
    copy_report: 'Скопировать отчет',
  },
  system:{
    empty_records: 'Нет записей',
    copy: 'копия',
    download_as_csv: 'Скачать в формате CSV',
    download_as_xls: 'Скачать в формате Excel'
  },
  title: 'Отчеты',
  choose_project: 'Выберите проект',
  empty: 'Отчетов не найдено',
  changed_status: 'Статус изменен',
  filter_title: 'Фильтровать записи отчета',
  filter_clear: 'Очистить',
  filter_settings: 'Условия отбора записей в отчет',
  select: {
    all_projects: 'Все аналитические проекты',
  },
  settings: 'Настройки',
  not_found: 'Отчет не найден',
  button_settings: {
    save: 'Сохранить',
    remove: 'Удалить',
    cancel: 'Отмена',
  },
  status: {
    created: 'Новый',
    life: 'LIVE',
    updating: 'Обновление',
    done: 'Готов',
    archive: 'Архив',
    pause: 'Пауза',
    deleting: 'Удаление',
    error: 'Ошибка',
  },
  records_table:{
    actions: 'Действия',
    duration: 'Длительность',
  },
  table: {
    report_name: 'Название отчета',
    analytic_project: 'Аналитический проект',
    status: 'Статус',
    date_created: 'Дата создания',
    date_next_update: 'Дата следующего обновления',
    actions: 'Действия',
    download_report: 'Скачать отчет',
  },
  navigation: {
    charts: 'Графики и диаграммы',
    settings: 'Настройки',
    records: 'Записи отчета',
  },
  modal: {
    remove_report: 'Удалить отчет',
    confirm_remove_report: 'Все данные и настройки отчёта будут удалены.',
  },
  pagination: {
    calls: '%{call_number} звонков',
  },
};
