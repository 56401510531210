import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { recalculateMetrics } from '@app/api/analytics-api/analytics-metric.api';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';
import { AllStatusProject, GroupSharedStatus, ProjectItem } from '@app/interfaces/analytics';
import useTranslation from '@app/hooks/use-translation';
import { metricIconsData } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/data/data';
import { Button, CircleButton, EasyModal, TooltipWrapper } from '@ui';
import { TableItem } from '@app/components/table/table.type';
import {
  useDeleteProjectMetricMutation,
  useGetMetricTypesListQuery,
  useGetProjectMetricsListQuery,
  useLazyGetProjectMetricQuery,
} from '@app/store/api/metrics.api';
import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import { ColsWithoutTable } from '@app/components/rubber-table/blocks/cols-without-table';
import { ProjectMetricsSkeleton } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/project-metrics.skeleton';

import { useAppSelector } from '@app/store/store';

import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { TableColumn } from '@app/components/rubber-table/table.types';

import AccordionItemUI from '../../../../components/ui/Accordion/AccordionItem';
import AccordionUI from '../../../../components/ui/Accordion/Aссordion';
import Icon from '../../../../components/ui/icons';
import RubberTable from '../../../../components/rubber-table';

import { tableUtilIconsClassName } from '../../../records/blocks/records-table/records-table.styles';

import { Modals } from './blocks/modals';

// export type MetricPropsType = {
//   editModal: boolean;
//   setCreateMetric: React.Dispatch<React.SetStateAction<boolean>>;
//   createMetric: boolean;
//   setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
// };
type PropsType = { project?: ProjectItem | null };
export const MetricSettings: React.FC<PropsType> = ({ project }) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.metricTemplates');
  const [activeModal, setActiveModal] = React.useState<string>('');
  const [metricId, setMetricId] = React.useState('');
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [recalculateModal, setRecalculateModal] = React.useState<boolean>(false);
  const [editModal, setEditModal] = React.useState(false);
  const [createMetric, setCreateMetric] = React.useState(false);
  // api
  const { data: metricTypes, isLoading: metricTypesLoading } = useGetMetricTypesListQuery();
  const { data: dictionaries, isLoading: dictionariesLoading } = useGetAllDictionariesListQuery();
  const { data: aliases, isLoading: aliasesLoading } = useGetAliasesListQuery();
  const { data: allChannels, isLoading: allChannelsLoading } = useGetChannelsQuery();
  const { data: metricList, isLoading: metricListLoading } = useGetProjectMetricsListQuery(
    { project_id: id || '' },
    { skip: !id },
  );
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [deleteMetric] = useDeleteProjectMetricMutation();
  const [getSingleMetricSettings, { currentData, isLoading: metricSettingsLoading }] =
    useLazyGetProjectMetricQuery();
  const onClickCreateModal = () => {
    setEditModal(false);
    setCreateMetric(true);
  };

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<Array<string | undefined>>(
    userSettings?.accordions?.metrics || [],
  );
  useEffect(() => {
    setActiveAccordionItem(userSettings?.accordions?.metrics || []);
  }, [userSettings?.accordions?.metrics]);
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        userSettings?.accordions?.metrics &&
          updateUserSettings({
            ...userSettings,
            accordions: {
              ...userSettings?.accordions,
              metrics: [...(userSettings?.accordions?.metrics || []), event.id],
            },
          });
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = userSettings?.accordions?.metrics;
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            metrics: filteredAccordionListFromStorage,
          },
        });

        return prev.filter((item) => item !== event.id);
      }
    });
  };
  const circleButtonClick = (metric_id: string, type: string) => {
    if (!id) return;
    getSingleMetricSettings({ project_id: id, metric_id: metric_id }).then(() => {
      setEditModal(true);
      setActiveModal(type);
    });
  };
  const deleteMetricClick = () => {
    if (id) {
      const params = {
        project_id: id,
        metric_id: metricId,
      };
      deleteMetric(params)
        .unwrap()
        .then(() => {
          setDeleteModal(false);
        })
        .catch((data) => {
          data.status === 403 && toast.error(t('system.err403'));
        });
    }
  };
  const circleButtonTrashClick = (id: string) => {
    setMetricId(id);
    setDeleteModal(true);
  };
  const getChannelNameById = (id: string) => {
    if (id !== 'all') {
      return allChannels?.find((item) => item.settings_channels_id === id)?.name;
    } else {
      return t('all');
    }
  };
  const getAliasNameById = (id: string) => {
    if (id !== 'all') {
      return aliases?.aliases.find((item) => item.alias_id === id)?.name;
    } else {
      return t('all');
    }
  };
  const getTypeName = (type: string) => {
    return metricTypes?.find((item) => item.type === type)?.name;
  };

  const getDictionaryNameById = (id: string) => {
    return dictionaries?.find((e) => e.dictionary_id === id)?.name;
  };

  const getMetricSetting = (metricItem: MetricListArrType) => {
    if (metricItem.type === 'find-words') {
      if (
        metricItem.settings.wordlist?.length !== 0 ||
        metricItem.settings.dictionaries?.length !== 0
      ) {
        return (
          <div className="truncate max-h-[100px] flex flex-wrap items-center gap-[6px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px] w-fit max-w-[400px]">
            {metricItem.settings.dictionaries?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-[#FFE2F4] text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {getDictionaryNameById(e)}
                </span>
              );
            })}
            {metricItem.settings.wordlist?.map((wordItem, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-basic_app_bg text-0color text-[13px] rounded-[4px]"
                  key={wordItem + index}
                >
                  {wordItem}
                </span>
              );
            })}
          </div>
        );
      } else return t('none_settings');
    } else if (metricItem.type === 'meta') {
      return (
        <span>
          {metricItem.settings.alias_id && getAliasNameById(metricItem.settings.alias_id)}
        </span>
      );
    } else {
      return (
        <div className="max-w-[400px] h-full flex flex-wrap items-center gap-[12px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px]">
          <span className="text-[10px] text-0color">
            {t('first_secs') + ': ' + metricItem.settings.exclude_begin_sec}
          </span>
          <span className="text-[10px] text-0color">
            {t('last_secs') + ': ' + metricItem.settings.exclude_end_sec}
          </span>
          {metricItem.settings.threshold || metricItem.settings.threshold === 0 ? (
            <span className="text-[10px] text-0color">
              {t('pause_duration') + ': ' + metricItem.settings.threshold}
            </span>
          ) : null}
        </div>
      );
    }
  };

  const getLength = (group: string) => {
    const filtered = metricTypes?.filter((e) => e.group === group);
    const TypesValues = filtered?.map((e) => e.type);
    return metricList?.filter(({ type }) => TypesValues?.includes(type)).length;
  };
  const filteredArr = (group: string) => {
    const filtered = metricTypes?.filter((e) => e.group === group);
    const TypesValues = filtered?.map((e) => e.type);
    return metricList
      ?.filter(({ type }) => TypesValues?.includes(type))
      .map((dataItem) => ({
        ...dataItem,
        utils: (
          <div className="w-full justify-end flex">
            <div className="gap-[10px] flex items-start border-l-[1px] pl-[10px] border-[#F1F2F3] ">
              <CircleButton
                hintTitle={t('popup_hints.settings_button')}
                dataId={`${group}-${dataItem.name}-configure`}
                onClick={() => {
                  if (dataItem.metric_id) circleButtonClick(dataItem.metric_id, dataItem.type);
                }}
                icon="SettingIcon"
                size={19}
                className={tableUtilIconsClassName}
              />

              <CircleButton
                dataId={`${group}-${dataItem.name}-delete`}
                dataStatus={
                  !project?.owner && project?.shared === GroupSharedStatus.VIEW ? 'unable' : 'able'
                }
                disable={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
                hintTitle={t('popup_hints.delete_button')}
                icon="TrashIcon"
                size={17.5}
                onClick={() => {
                  if (!disableFunc()) circleButtonTrashClick(dataItem.metric_id);
                }}
                className={`mr-[18px] text-basic_red ${disableFunc() && '!text-4color'}`}
              />
            </div>
          </div>
        ),
        channel: (
          <div>
            <div>
              {(dataItem.settings.channel && getChannelNameById(dataItem.settings.channel)) || ''}
            </div>
          </div>
        ),
        metricSettings: <div className="py-[5px]">{getMetricSetting(dataItem)}</div>,
        type: (
          <div className="w-full flex items-center gap-[10px]">
            <Icon
              size={28}
              name={metricIconsData[dataItem.type.replace(/([.\-\\/ ])/g, '_')] || 'HelpCircleIcon'}
            />
            {getTypeName(dataItem.type)}
          </div>
        ),
        name: (
          <TooltipWrapper content={dataItem.name} id={dataItem.name + dataItem.metric_id}>
            <div>{dataItem.name}</div>
          </TooltipWrapper>
        ),
      })) as unknown as Array<TableItem>;
  };
  const disableFunc = () => {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    if (project?.status === AllStatusProject.PAUSE) {
      return false;
    }
    return project?.status !== AllStatusProject.CREATED;
  };

  const tables = useMemo(
    () => [
      {
        name: t('meta_metrics'),
        group: 'meta',
        id: '32be7696-77a4-4d98-8510-9a2d6f9c8cc7',
      },
      {
        name: t('vad_metrics'),
        group: 'vad',
        id: '33be7696-77a4-4d98-8510-9a2d6f9c8cc8',
      },
      {
        name: t('speech_metrics'),
        group: 'speech',
        id: '35be7696-77a4-4d98-8510-9a2d6f9c8cc9',
      },
      {
        name: t('other_metrics'),
        group: 'other',
        id: '34be7696-77a4-4d98-8510-9a2d6f9c8ce7',
      },
    ],
    [t],
  );
  const columns: TableColumn[] = [
    {
      title: t('table.name'),
      index: 'name',
    },
    {
      title: t('table.type'),
      index: 'type',
    },
    {
      title: t('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: t('table.settings'),
      index: 'metricSettings',
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 110,
    },
  ];

  const getTableColumns = (group: string) => {
    if (group === 'meta') {
      const result = columns.filter((item) => item.index !== 'channel');
      return result.map((column, index) =>
        index === 0
          ? { ...column, maxWidth: '65%' }
          : index === 2
          ? { ...column, maxWidth: '70%' }
          : column,
      );
    }
    return columns;
  };
  const recalculateMetricsClick = ({ tags_changed }: { tags_changed?: boolean }) => {
    id &&
      recalculateMetrics(id, tags_changed)
        .then(() => {
          toast.success(t('success'));
        })
        .catch(() => {
          toast.success(t('error'));
        })
        .finally(() => {
          setRecalculateModal(false);
        });
  };

  useEffect(() => {
    const accordionListFromStorage = userSettings?.accordions?.metrics;
    if (!accordionListFromStorage?.length) {
      const defaultGroup = tables[0].id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup &&
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            metrics: [defaultGroup],
          },
        });
    }
  }, [tables, updateUserSettings, userSettings]);

  if (
    metricTypesLoading ||
    dictionariesLoading ||
    aliasesLoading ||
    allChannelsLoading ||
    metricListLoading
  )
    return <ProjectMetricsSkeleton />;
  return (
    <>
      <div className="flex justify-between items-center py-[25px]">
        <h2 className="text-[24px]">{t('metric')}</h2>
        <div className="flex items-center relative z-[51] gap-[10px]">
          <Button
            disabled={disableFunc()}
            icon="CirclePlusIcon"
            label={t('add_metric')}
            onClick={() => onClickCreateModal()}
          />
          <TooltipWrapper id="recalculate_metric" content={t('popup_hints.recalculate_metric')}>
            <Button onClick={() => setRecalculateModal(true)} fill="outlined" icon="RefreshIcon" />
          </TooltipWrapper>
        </div>
      </div>
      <ColsWithoutTable columns={columns} />
      <AccordionUI>
        {tables.map((table) => {
          return (
            <AccordionItemUI
              variant={'filled'}
              key={table.id}
              id={table.id}
              dataId={`ap-metric-${table.name}`}
              disabled={
                activeAccordionItem.length === 1 ? activeAccordionItem[0] === table.id : false
              }
              title={
                <div className="flex items-center font-[700] text-[16px] text-2color leading-[110%] tracking-tight">
                  {table.name}
                  <div className="flex font-[400] justify-center items-center text-xs w-[20px] h-[20px] rounded-full ml-3 bg-defaultBgBtn text-white">
                    {getLength(table.group)}
                  </div>
                </div>
              }
              clickBtnChild={clickAccordionHeaderBtn}
              active={activeAccordionItem.includes(table.id)}
            >
              <RubberTable
                withoutCols
                columns={getTableColumns(table.group)}
                dataSource={filteredArr(table.group)}
              />
            </AccordionItemUI>
          );
        })}
      </AccordionUI>
      <EasyModal
        onClose={setDeleteModal.bind(null, false)}
        show={deleteModal}
        onRemove={() => deleteMetricClick()}
        variant="removal"
        withoutFooter
        label={t('warning')}
      >
        <div className="px-[20px] text-center">{t('metric_delete_confirm')}</div>
      </EasyModal>
      {metricTypes && allChannels && (
        <Modals
          status={project?.status}
          setEditModal={setEditModal}
          setActiveModal={setActiveModal}
          activeModal={activeModal}
          firstModal={createMetric}
          setFirstModal={setCreateMetric}
          editModal={editModal}
          metricTypes={metricTypes}
          allChannels={allChannels}
          viewOnly={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
          metricSettings={currentData}
          metricSettingsLoading={metricSettingsLoading}
          metricList={metricList}
        />
      )}
      <EasyModal
        onClose={() => setRecalculateModal(false)}
        withoutFooter
        variant="small"
        show={recalculateModal}
      >
        <div className="w-full p-4">
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <div className="">
              <Icon
                size={30}
                className="text-defaultColor p-4 bg-defaultColor bg-opacity-10 rounded-full"
                name={'HelpCircleIcon'}
              />
            </div>
            <div className="block">
              <div className="text-[14px]  leading-normal text-center w-full text-1color font-medium">
                {t('recalculate_modal')
                  .split(/[!.]/g)
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      {i}
                      {index === 0 ? '!' : '.'} <br />
                    </React.Fragment>
                  ))}
              </div>
            </div>
            <div className="flex items-center justify-center flex-col gap-[10px]">
              <Button onClick={() => recalculateMetricsClick({})} label={t('recalculate')} />
              <Button
                onClick={() => recalculateMetricsClick({ tags_changed: true })}
                label={t('recalculate_manual_metric_only')}
              />
            </div>
          </div>
        </div>
      </EasyModal>
    </>
  );
};
