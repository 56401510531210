import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuthContext } from '@app/utils/auth-provider';
import { useGetUserInfoQuery } from '@app/store/api/get-user-info.api';
import { Loading } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { useGetUserSettingsQuery } from '@app/store/api/user-settings.api';

import { BreadcrumbsItem } from './blocks/breadcrumbs/breadcrumbs.type';
import HeaderBlock from './blocks/header.block';
import FooterBlock from './blocks/footer.block';
import LayoutContainerBlock from './blocks/layout-container.block';
import MenuBar from './blocks/menu-bar/menu-bar';

const faviconRtk = require('../../imgs/favicon/faviconRtk.png');
const faviconDefault = require('../../imgs/favicon/faviconDefault.png');

type LayoutWithAuthProps = {
  breadcrumbs?: Array<BreadcrumbsItem>;
};

const LayoutOutlet: FC<LayoutWithAuthProps> = () => {
  const { isAuth } = useAuthContext();
  const [layoutLoading, setLayoutLoading] = useState(true);
  const { isLoading: loading } = useGetUserSettingsQuery(undefined, { skip: !isAuth });
  const { isFetching } = useGetUserInfoQuery(undefined, { skip: !isAuth });
  const { t } = useTranslation('common.pageTitle');
  useEffect(() => {
    if (isFetching || loading) {
      setLayoutLoading(true);
    } else {
      setTimeout(() => setLayoutLoading(false), 700);
      // clearTimeout(timeout);
    }
  }, [isFetching, loading]);
  if (!isAuth)
    return (
      <div className="bg-basic_app_bg">
        <Outlet />
      </div>
    );

  if (layoutLoading) return <Loading />;

  return (
    <div className="bg-basic_app_bg  border-l-[3px] border-l-speech_analitics">
      <HeaderBlock />
      <div className="flex flex-row">
        <MenuBar />
        <div className="flex flex-col flex-1 relative overflow-hidden">
          <LayoutContainerBlock>
            <Outlet />
          </LayoutContainerBlock>
          <FooterBlock />
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={process.env.REACT_APP_THEME === 'rtk' ? faviconRtk : faviconDefault}
              sizes="16x16"
            />
            <title>{t('title')}</title>
          </Helmet>
        </div>
      </div>
    </div>
  );
};

export default LayoutOutlet;
