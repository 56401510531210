import React from 'react';
import { Skeleton } from '@ui';

const ChartListBlockHeaderSkeletonLoading = () => {
  return (
    <div className="h-[58px] w-full">
      <div className="py-[10px] w-full flex items-center gap-[13px] ">
        <Skeleton className="mr-[80px]" />
        <Skeleton width={210} />
        <Skeleton width={42} />
        <Skeleton width={42} />
      </div>
    </div>
  );
};

export default ChartListBlockHeaderSkeletonLoading;
