import React from 'react';

import useTranslation from '@app/hooks/use-translation';

import { Breadcrumbs } from './breadcrumbs';

const FooterBlock = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { t } = useTranslation('components.footer');
  return (
    <div className="h-[40px] bg-bg_3 w-full">
      <div className="inline-flex w-full items-center h-full justify-between px-[20px]">
        <div className="inline-flex items-center">
          <Breadcrumbs />
        </div>
        {process.env.REACT_APP_THEME !== 'rtk' && (
          <div className="text-[14px] font-[500px] inline-flex items-center text-2color">
            <div className="mr-[20px]">{t('version')}</div>
            <div>
              {process.env.REACT_APP_THEME === 'calltraffic'
                ? t('copyrightCallTraffic')
                : t('copyright')}
              {new Date().getFullYear()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterBlock;
