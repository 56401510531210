import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const Classifier: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_10498_31456)">
          <path stroke="#2987CC" strokeWidth="2" d="M20 20H38V38H20z"></path>
          <path
            fill="#2987CC"
            d="M34.193 25.137a1.124 1.124 0 01-1.143-1.114c0-.607.498-1.113 1.143-1.113.644 0 1.142.506 1.142 1.113 0 .608-.498 1.114-1.142 1.114zm-1.07 8.862v-7.852h2.14V34h-2.14zM22.203 33.998l3.692-10.568h2.6l3.684 10.569h-2.322l-.798-2.564h-3.736L24.525 34h-2.322zm4.966-8.517l-1.333 4.299h2.71l-1.333-4.3h-.044z"
          ></path>
          <circle cx="3" cy="3" r="3" fill="#2987CC" transform="matrix(-1 0 0 1 22 16)"></circle>
          <path fill="#EEF9FD" d="M18 19a1 1 0 102 0 1 1 0 00-2 0z"></path>
          <circle cx="3" cy="3" r="3" fill="#2987CC" transform="matrix(-1 0 0 1 42 16)"></circle>
          <path fill="#EEF9FD" d="M38 19a1 1 0 102 0 1 1 0 00-2 0z"></path>
          <circle cx="3" cy="3" r="3" fill="#2987CC" transform="matrix(-1 0 0 1 42 37)"></circle>
          <path fill="#EEF9FD" d="M38 40a1 1 0 102 0 1 1 0 00-2 0z"></path>
          <circle cx="3" cy="3" r="3" fill="#2987CC" transform="matrix(-1 0 0 1 22 37)"></circle>
          <path fill="#EEF9FD" d="M18 40a1 1 0 102 0 1 1 0 00-2 0z"></path>
        </g>
        <defs>
          <clipPath id="clip0_10498_31456">
            <path fill="#fff" d="M0 0H26V27H0z" transform="translate(16 16)"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Classifier;
